<template>
  <div class="user-details">
    <div class="dosing-and-setup-column">
      <div class="dosing-wrapper">
        <div class="dosing-status">
          <el-popover v-if="isDosingUser" class="popover" placement="right" width="300" trigger="hover">
            <p class="popover-content">
              {{ dosingDescription }}
            </p>
            <p slot="reference">
              <i class="el-icon-info" /> This user {{ isDosingUser ? "can" : "cannot" }} calculate doses.
            </p>
          </el-popover>
          <a-popconfirm
            v-if="showDosingControl"
            placement="topRight"
            ok-text="Yes"
            cancel-text="No"
            @confirm="handleDosingAccess(isDosingUser ? 'REMOVE' : 'ADD')"
          >
            <template slot="title">
              <p>Are you sure you want to {{ isDosingUser ? "remove" : "give" }} dosing access this user?</p>
            </template>
            <BaseButton class="inline-button" icon="none">
              {{ isDosingUser ? "Remove" : "Give" }} dosing access
            </BaseButton>
          </a-popconfirm>
        </div>
      </div>
      <div
        class="action-btn-wrapper"
        :class="{
          'justify-end': !showSetPassword,
        }"
      >
        <BaseButton
          v-if="!hasFinishAccountSetup && showSetPassword"
          type="secondary-small"
          class="finish-setup"
          @click="handleFinishSetupModal"
        >
          {{ user.owner ? "Finish Setup" : "Set New Password" }}
        </BaseButton>
        <BaseButton
          v-else-if="showSetPassword"
          type="secondary-small"
          class="finish-setup"
          @click="handleFinishSetupModal"
        >
          Reset Password
        </BaseButton>
        <a-popconfirm
          v-if="showDeleteControl"
          placement="topRight"
          ok-text="Yes"
          cancel-text="No"
          @confirm="$emit('handleDeleteUser')"
        >
          <template slot="title">
            <p>Are you sure you want to delete this user?</p>
          </template>
          <BaseButton type="secondary-small">
            <font-awesome-icon icon="trash" /> Delete User
          </BaseButton>
        </a-popconfirm>
      </div>
    </div>

    <!-- USER DETAILS CONTENT -->
    <UserInfo :contact-details="user" :is-edit-info-visible="isEditInfoVisible" />
    <UserLocations :my-roles="myRoles" :get-my-info="getMyInfo" :user="user" />
    <UserPermissions :my-roles="myRoles" :get-my-info="getMyInfo" :user="user" :isDosingUser="isDosingUser" />
    <!-- END CONTENT -->

    <OrgUserResetPassword
      v-if="finishSetupModal"
      :user="user"
      :finish-setup-modal="finishSetupModal"
      :has-finish-account-setup="hasFinishAccountSetup"
      @handleFinishSetupModal="handleFinishSetupModal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UserPermissions from "./UserPermissions.vue";
import UserLocations from "./UserLocations.vue";
import BaseButton from "@/components/common/BaseButton.vue";
import UserInfo from "@/components/dashboard/UserInfo.vue";
import userMe from "@/services/user-me";
import OrgUserResetPassword from "./OrgUserResetPassword.vue";
import notification from "@/utils/notifications";

export default {
  name: "UserDetails",
  components: {
    BaseButton,
    UserInfo,
    UserPermissions,
    UserLocations,
    OrgUserResetPassword,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    isEditInfoVisible: {
      type: Boolean,
      required: false,
    },
    getMyInfo: {
      type: Object,
      required: false,
    },
    hasFinishAccountSetup: Boolean,
  },
  data() {
    return {
      finishSetupModal: false,
      finishSetupSuccessModal: false,
      newPassword: "",
      loadingModal: false,
      dosingDescription:
        "Dosing users are available as doctors when creating appointments, and have the ability to calculate BHRT doses.",
    };
  },
  computed: {
    ...mapGetters(["getOrgId", "getUserAccountSetUp", "getFinalizeSetUpData", "getCurrentUser"]),
    ...mapGetters("accountsModule", ["getOrgDosingDetails", "getOrgDosingUsers"]),
    myRoles() {
      return userMe.getMyRoles();
    },
    showSetPassword() {
      return this.getMyInfo.sim.simpatraAdmin && this.user.active;
    },
    showDeleteControl() {
      const { isSimpatraAdmin, isOrgOwner } = userMe.getMyRoles();
      const isAllowed = isSimpatraAdmin || isOrgOwner;
      return isAllowed && !this.isOrgOwner;
    },
    showDosingControl() {
      const currentUserIsSuperAdmin = userMe.getMyRoles().isSimpatraAdmin;
      if(currentUserIsSuperAdmin){
        return true;
      } else {
        return !this.isOrgOwner;
      }
    },
    showAddDosingControl() {
      return !this.isDosingUser && !isMaxDosingUser;
    },
    isOrgOwner() {
      return this.user.owner;
    },
    isDosingUser() {
      return this.getOrgDosingUsers.some((dosingUser) => this.user.id === dosingUser.user.id);
    },
    maxDosingUsers() {
      return this.getOrgDosingDetails && this.getOrgDosingUsers.length >= this.getOrgDosingDetails.numDosingUsers;
    },
  },
  methods: {
    ...mapActions("accountsModule", [
      "fetchOrgDosingUsers",
      "removeDosingAccess",
      "addDosingAccess",
      "fetchUserAccountSetupStatus",
    ]),
    handleFinishSetupModal() {
      this.finishSetupModal = !this.finishSetupModal;
    },
    async handleDosingAccess(type) {
      const payload = {
        orgId: this.getOrgId,
        userId: this.user.id,
      };

      if (type === "ADD") {
        if (this.maxDosingUsers) {
          notification(
            "warning",
            "You have reached the maximum amount of dosing licenses for your account.  Please contact sales to increase users."
          );
        } else {
          await this.addDosingAccess(payload);
        }
      } else {
        await this.removeDosingAccess(payload);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.action-btn-wrapper {
  margin: auto 70px;
  width: 22%;
  display: flex;
  justify-content: space-between;

  &.justify-end {
    justify-content: flex-end;
  }
}
.user-details {
  margin: 10px -11px -11px -11px;
  display: flex;
  flex-flow: row wrap;
  transition: all 0.2s ease;
}
.dosing-and-setup-column {
  display: flex;
  padding: 10px 0;
  align-items: center;
  width: 100%;
  // height: 50px;
  // margin: -1px;
  border-top: 1px solid #e6e6e6;
  justify-content: space-between;
}
.dosing-status {
  display: flex;
  margin-left: 25px;
}
.inline-button {
  text-decoration: underline;
}
.el-icon-info {
  color: $dark-blue;
}
p {
  color: $dark-blue;
}
.popover-content {
  word-break: keep-all;
}
.modal-card {
  width: 500px;
}
.modal-body {
  text-align: left;
}
.modal-body p {
  font-size: 14px;
  font-weight: bold;
}
.modal-body .form-el {
  margin-bottom: 16px;
}
.modal-body .cta {
  float: right;
}
</style>
