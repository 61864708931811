<style lang="scss" scoped>
.button {
  margin-top: 0 !important;
}
</style>
<template>
  <div class="finish-setup-modal">
    <BaseModal :is-visible="finishSetupModal" :is-loading="loadingModal" :handle-close="() => $emit('handleFinishSetupModal')" :title="getModalDetails.title">
      <a-form>
        <div class="modal-body">
          <form-group :label="getModalDetails.label" :validator="$v.userPassword">
            <a-input class="m-top-10" v-model="userPassword" :type="showPassword ? 'text' : 'password'" placeholder="Insert new password" @input="() => $v.userPassword.$touch()">
              <template #prefix>
                <a-icon type="lock" />
              </template>
              <template #addonAfter>
                <button class="button borderless background-transparent cursor-pointer" @click="showPassword = !showPassword">
                  <font-awesome-icon class="icon" :icon="showPassword ? 'eye-slash' : 'eye'" />
                </button>
              </template>
            </a-input>
          </form-group>
        </div>
      </a-form>
      <div class="action-btns">
        <BaseButton :disabled="disableBtn" type="cta btn" @click="handleFinalizeSetUp()">
          {{ getModalDetails.btnLabel }}
        </BaseButton>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import BaseModal from "@/components/common/AntDesign/BaseModal.vue";
import BaseButton from "@/components/common/BaseButton.vue";

const validatePassword = value => value !== "";

export default {
  name: "OrgUserResetPassword",
  components: {
    BaseModal,
    BaseButton
  },
  props: {
    user: Object,
    finishSetupModal: Boolean,
    hasFinishAccountSetup: Boolean
  },
  data() {
    return {
      userPassword: "",
      loadingModal: false,
      showPassword: false
    };
  },
  computed: {
    getModalDetails() {
      if (this.hasFinishAccountSetup) {
        return {
          title: "Reset Password",
          label: "Set new password",
          btnLabel: "Save"
        };
      } else {
        return {
          title: "Finish Setup Success",
          label: "Set Temporary Password for Customer and Activate",
          btnLabel: "Finalize"
        };
      }
    },
    disableBtn() {
      return this.loadingModal || this.userPassword === "";
    }
  },
  validations: {
    userPassword: {
      "Please insert password": validatePassword
    }
  },
  methods: {
    ...mapActions("accountsModule", ["updateOrgUserPassword"]),
    async handleFinalizeSetUp() {
      this.loadingModal = true;
      await this.updateOrgUserPassword({
        userId: this.user.id,
        payload: {
          password: this.userPassword
        }
      });
      this.$emit("handleFinishSetupModal");
      this.loadingModal = false;
    },
    toggleShow() {}
  }
};
</script>

