<template>
  <div class="user-info">
    <div class="header">
      <h2>Info</h2>
      <template v-if="isEditInfoVisible">
        <BaseButton v-if="!formActive" icon="el-icon-edit" @click="toggleForm"> Edit </BaseButton>
        <BaseButton v-else icon="el-icon-back" @click="toggleForm"> Back </BaseButton>
      </template>
    </div>
    <div v-if="formActive === false" class="display">
      <div>
        <p class="info-label">First Name</p>
        <p class="editable">
          {{ userDetails.firstName }}
        </p>
      </div>
      <div>
        <p class="info-label">Last Name</p>
        <p class="editable">
          {{ userDetails.lastName }}
        </p>
      </div>
      <div>
        <p class="info-label">Email</p>
        <p class="editable">
          {{ userDetails.email }}
        </p>
      </div>
      <div>
        <p class="info-label">Phone</p>
        <p class="editable">
          {{ userDetails.phone }}
        </p>
      </div>
    </div>
    <div v-if="formActive === true" class="edit-form">
      <FormUserInfo
        submit-text="Save Info"
        :user="userDetails"
        user-action="edit"
        @updateUserData="updateUserData"
        @toggleForm="toggleForm"
      />
    </div>
  </div>
</template>

<script>
import FormUserInfo from "@/components/common/FormUserInfo.vue";
import BaseButton from "@/components/common/BaseButton.vue";

export default {
  name: "UserInfo",
  components: {
    BaseButton,
    FormUserInfo,
  },
  props: {
    contactDetails: {
      type: Object,
      required: true,
    },
    isEditInfoVisible: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      formActive: false,
      userDetails: {},
    };
  },
  mounted() {
    this.userDetails = Object.assign({}, this.contactDetails);
  },
  methods: {
    toggleForm() {
      this.formActive = !this.formActive;
    },
    updateUserData(user) {
      this.contactDetails.firstName = user.firstName;
      this.contactDetails.lastName = user.lastName;
      this.contactDetails.email = user.email;
      this.contactDetails.phone = user.phone;
      // Re-assign object to refresh updated values
      this.userDetails = Object.assign({}, this.contactDetails);
    },
  },
};
</script>

<style lang="scss" scoped>
.user-info {
  flex: 1;
  padding: 25px;
  position: relative;
  border-top: 1px solid #e6e6e6;
  margin-right: -1px;
  border-bottom-left-radius: 4px;
  min-width: 300px;
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    h2 {
      flex: 1;
      color: $dark-blue;
      font-size: 16px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin: 0;
    }
  }
  p {
    margin: 0;
  }
  .info-label {
    font-size: 12px;
    font-weight: 600;
    color: $dark-blue;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  .editable {
    padding: 5px 10px;
    margin: 0px 0px 10px -10px;
    margin-bottom: 10px;
    display: inline-block;
    transition: all 0.2s ease;
  }
}
</style>
