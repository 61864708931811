<template>
  <div class="user-locations">
    <div class="header">
      <h2>Locations</h2>
      <template v-if="displayEditLocation">
        <BaseButton v-if="!formActive" icon="el-icon-edit" @click="toggleForm"> Edit </BaseButton>
        <BaseButton v-else icon="el-icon-back" @click="toggleForm"> Back </BaseButton>
      </template>
    </div>
    <div v-if="!formActive" class="location-list">
      <el-tag v-for="(location, index) in userLocations" :key="index" type="success" class="location-tag" size="small">
        {{ location.name }}
      </el-tag>
    </div>
    <FormUserLocations
      v-else
      :user="user"
      submit-text="Save Locations"
      user-action="edit"
      :selected-location-ids="selectedLocationIds"
      @updateLocations="updateLocations"
      @toggleForm="toggleForm"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { isEmpty } from "lodash";
import BaseButton from "@/components/common/BaseButton.vue";
import FormUserLocations from "@/components/common/FormUserLocations.vue";

export default {
  name: "UserLocations",
  components: {
    BaseButton,
    FormUserLocations,
  },
  props: {
    user: Object,
    myRoles: {
      type: Object,
      required: false,
    },
    getMyInfo: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      formActive: false,
      userLocations: [],
      selectedLocationIds: [],
    };
  },
  computed: {
    ...mapGetters("locationsModule", ["getCurrentUserAvailableLocations"]),
    displayEditLocation() {
      if (this.myRoles.isLocationAdmin) {
        return this.user.email !== this.getMyInfo.sim.user.email && !this.user.admin;
      }
      return !this.user.simpatraAdmin && !this.user.owner;
    },
  },
  mounted() {
    this.userLocations = Object.assign([], this.user.locations);
    if (!isEmpty(this.userLocations)) {
      this.constructSelectedLocations();
    }
  },
  methods: {
    constructSelectedLocations() {
      let selectedLocationIds = [];
      this.userLocations.forEach((myLocation) => {
        const locationSelected = this.getCurrentUserAvailableLocations.find((location) => location.id === myLocation.id);

        if (locationSelected) selectedLocationIds.push(locationSelected.id);
      });

      this.selectedLocationIds = selectedLocationIds;
    },
    toggleForm() {
      this.formActive = !this.formActive;
    },
    updateLocations(data) {
      this.userLocations = data.locations;
      this.user["locations"] = data.locations;
      this.constructSelectedLocations();
    },
  },
};
</script>

<style lang="scss" scoped>
.user-locations {
  flex: 1;
  padding: 25px;
  position: relative;
  border: 1px solid #e6e6e6;
  border-bottom: none;
  margin-right: -1px;
  min-width: 300px;
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    h2 {
      flex: 1;
      color: $dark-blue;
      font-size: 16px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin: 0;
    }
  }
  p {
    margin: 0;
  }
  .location-tag {
    margin: 0px 10px 10px 0px;
    color: #6b97b9;
    border-color: #6b97b921;
    background-color: #6b97b90f;
  }
}
@media screen and (max-width: 1266px) {
  .user-locations {
    margin-bottom: -1px;
  }
}
</style>
