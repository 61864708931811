<template>
  <div class="user-permissions">
    <div class="header">
      <h2>Permissions</h2>
      <template v-if="displayEdit">
        <BaseButton v-if="!formActive" icon="el-icon-edit" @click="toggleForm"> Edit </BaseButton>
        <BaseButton v-else icon="el-icon-back" @click="toggleForm"> Back </BaseButton>
      </template>
    </div>
    <div v-if="formActive === false">
      <p class="info-label organization-div">Administrator</p>
      <el-tag v-if="user.admin" type="success" class="permission-tag" size="small"> Organization Admin </el-tag>
      <el-tag v-else-if="userIsLocationAdmin" type="success" class="permission-tag" size="small">
        Location Admin
      </el-tag>
      <el-tag v-else type="success" class="permission-tag" size="small"> None </el-tag>
      <el-tag v-if="isApproverUser !== ''" type="success" class="permission-tag" size="small">
        {{ isApproverUser }}
      </el-tag>
      <p class="info-label">Location</p>
      <template v-if="displayPermissions.length > 0">
        <el-tag
          v-for="(permission, index) in displayPermissions"
          :key="index"
          type="success"
          class="permission-tag"
          size="small"
        >
          {{ permission.name }}
        </el-tag>
      </template>
      <template v-else>
        <el-tag type="error" size="small"> none </el-tag>
      </template>
      <div v-if="myRoles.isSimpatraAdmin" class="directory-div">
        <p class="info-label">Directory Management</p>
        <el-tag type="error" class="directory-tag" size="small">
          {{ checkDirectory }}
        </el-tag>
      </div>
    </div>
    <FormUserPermissions
      v-else
      submit-text="Save Permissions"
      :user="user"
      user-action="edit"
      @toggleForm="toggleForm"
      @updatePermissions="updatePermissions"
      :isDosingUser="isDosingUser"
    />
  </div>
</template>

<script>
import { some } from "lodash";
import BaseButton from "@/components/common/BaseButton.vue";
import FormUserPermissions from "@/components/common/FormUserPermissions.vue";

export default {
  name: "UserPermissions",
  components: {
    BaseButton,
    FormUserPermissions,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    myRoles: {
      type: Object,
      required: true,
    },
    getMyInfo: {
      type: Object,
      required: true,
    },
    isDosingUser: Boolean
  },
  data() {
    return {
      formActive: false,
    };
  },
  computed: {
    checkDirectory() {
      const orgDirectory = this.user.organizationPermissions.findIndex((item) => {
        return item.name === "OrgDirectory";
      });
      if (this.user.organizationPermissions[orgDirectory]) {
        return this.user.organizationPermissions[orgDirectory].value === 0 ? "none" : "Included";
      }

      return "none";
    },
    isApproverUser() {
      let value = "";
      this.user.organizationPermissions.forEach((permission) => {
        if (permission.name === "Approver") {
          if (permission.value === 2) {
            value = "Approver";
          } else {
            value = "";
          }
        } else {
          value = "";
        }
      });
      return value;
    },
    displayPermissions() {
      // If permission value = 1, show as View <permission>
      // if permission value = 2, show as Manage <permission>
      // Strip prepended 'Loc' from permission name strings
      const userPermissions = [];
      this.user.locationPermissions.forEach((permission) => {
        if (permission.value === 1) {
          userPermissions.push({
            name: `View ${permission.name.substring(3)}`,
          });
        }
        if (permission.value === 2) {
          userPermissions.push({
            name: `Manage ${permission.name.substring(3)}`,
          });
        }
      });

      return userPermissions;
    },
    displaySuperAdminPermissions() {
      const superAdminPermissions = [];
      this.user.superAdminPermissions.forEach((permission) => {
        if (permission.value === 1) {
          superAdminPermissions.push({
            name: `${permission.name.substring(3).split("Management")[0]} Management`,
          });
        }
      });
      return superAdminPermissions;
    },
    userIsLocationAdmin() {
      return some(this.user.locationPermissions, (permission) => {
        return permission.name === "LocAdmin" && permission.value === 2;
      });
    },
    displayOrgAdminRole() {
      const { isOrgOwner, isSimpatraAdmin, isOrgAdmin } = this.myRoles;
      return isOrgOwner || isSimpatraAdmin || isOrgAdmin;
    },
    displayEdit() {
      const { isLocationAdmin, isOrgAdmin } = this.myRoles;
      if (this.user.email !== this.getMyInfo.sim.user.email) {
        return (!this.user.owner && isOrgAdmin) || (isLocationAdmin && !this.user.admin);
      }
      return !this.user.owner;
    },
  },
  methods: {
    toggleForm() {
      this.formActive = !this.formActive;
    },
    updatePermissions({ type, data }) {
      this.user[type] = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-permissions {
  flex: 1.5;
  padding: 25px;
  position: relative;
  border: 1px solid #e6e6e6;
  min-width: 400px;
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    h2 {
      flex: 1;
      color: $dark-blue;
      font-size: 16px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin: 0;
    }
  }
  p {
    margin: 0;
  }
  .edit-button {
    position: absolute;
    top: 20px;
    right: 20px;
    transition: all 0.2s ease;
  }
  .info-label {
    font-size: 12px;
    font-weight: 600;
    color: $dark-blue;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 5px;
  }
  .permission-list {
    display: inline-block;
    list-style-type: none;
    padding: 5px 10px;
    margin: 0px 0px 10px -10px;
    transition: all 0.2s ease;
    li {
      line-height: 20px;
    }
  }
  .business-permissions {
    margin-left: 25px;
  }
}
.permission-list-container {
  display: flex;
}
h2 {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  color: $dark-blue;
  letter-spacing: 1px;
  margin-bottom: 10px;
}
.edit-enter,
.edit-leave-to {
  visibility: hidden;
  opacity: 0;
}
.permission-tag {
  margin: 0px 10px 10px 0px;
  color: #6b97b9;
  border-color: #6b97b921;
  background-color: #6b97b90f;
}
.organization-div,
.directory-div {
  margin-top: 10px !important;
}
.approver-tag {
  margin: 0px 10px 10px 0px;
  color: #7cd0d4;
  border-color: #7cd0d4;
  background-color: #fff;
}
</style>
